import { NgTemplateOutlet, NgClass } from '@angular/common'
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterLinkActive, RouterLink } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@jsverse/transloco'
import { Store } from '@ngrx/store'

import { UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { closeMobileMenu } from '~core/store/ui/ui.actions'
import { UpgradeDialogComponent } from '~features/subscription/components/upgrade-dialog/upgrade-dialog.component'
import { IconFill, Icon } from '~icons'

export interface SidebarItemUltraslim {
  label?: string
  labelPath?: string
  path: string
  perms?: string[]
}

export interface SidebarItemSlim {
  children?: SidebarItemUltraslim[]
  icon?: IconFill | Icon
  label?: string
  labelPath?: string
  path?: string
  perms?: string[]
  action?: string
}

export type ToolbarAction =
  | 'ADD'
  | 'ADD_COMMENT'
  | 'ADD_CONTENT'
  | 'ADD_CHART'
  | 'ADD_IMAGE'
  | 'ADD_LIST'
  | 'ADD_TEXT'
  | 'SAVE_AS_TEMPLATE'
  | 'SAVE'
  | 'SETTINGS'

export interface SidebarItem {
  action?: ToolbarAction
  disableClick?: boolean
  forceActiveStatus?: boolean
  children?: SidebarItemSlim[]
  divider?: boolean
  exact?: boolean
  hasCustomLayout?: boolean
  icon?: IconFill | Icon
  label?: string
  labelPath?: string
  path?: string
  perms?: string[]
  spacer?: boolean
}

@Component({
  selector: 'sb-sidebar',
  template: `
    <ng-container *transloco="let t">
      <!--  First level nav list -->
      <mat-nav-list class="sidebar">
        @for (item of items; track item) {
          <ng-container
            [ngTemplateOutletContext]="{ item, level: 0 }"
            [ngTemplateOutlet]="item.hasCustomLayout ? itemLayoutTemplateRef : sidebarItem"
          />
          <!-- Second level nav list -->
          @if (item.children?.length > 0) {
            <mat-nav-list [ngClass]="{ expanded: isMobileSidebar || expanded }">
              @for (child of item.children; track child; let index = $index) {
                <ng-container
                  [ngTemplateOutletContext]="{ item: child, index: this.index, level: 1 }"
                  [ngTemplateOutlet]="sidebarItem"
                />
              }
            </mat-nav-list>
          }
          @if (item.spacer) {
            <div class="spacer"></div>
          }
          @if (item.divider) {
            <mat-divider />
          }
        }
      </mat-nav-list>

      <!-- Nav list item -->
      <ng-template #sidebarItem let-index="index" let-item="item" let-level="level">
        <a
          (click)="!hasPermissions(item) ? openUpgradeDialog() : onClickNavItem(item)"
          [ngClass]="{
            'has-children': item.children?.length > 0 && level === 0,
            right: side === 'right',
            'force-active': item?.forceActiveStatus,
            'disable-click': item.disableClick
          }"
          [routerLinkActiveOptions]="{ exact: !!item.exact }"
          [routerLink]="hasPermissions(item) ? item.path : null"
          routerLinkActive="active"
          [matTooltipPosition]="side === 'left' ? 'right' : 'left'"
          mat-list-item
          [matTooltip]="!expanded ? item.label ?? t(item.labelPath) : ''"
        >
          <mat-icon [svgIcon]="item.icon" class="default" matListItemIcon />
          <mat-icon [svgIcon]="item.icon + '-fill'" class="active" matListItemIcon />
          @if (isMobileSidebar || expanded) {
            <div matListItemTitle class="sidebar-label">
              {{ item.label ?? t(item.labelPath) }}
            </div>
          }
        </a>
      </ng-template>
    </ng-container>
  `,
  styles: [
    `
      @use 'variables';
      @use 'mixins';

      sb-sidebar {
        mat-nav-list.sidebar {
          padding: variables.$nav-list-padding-y variables.$nav-list-padding-x;

          .mat-mdc-list-item {
            // First level item
            @include mixins.nav-list-item();

            &.has-children {
              + mat-nav-list {
                height: 0; // By default subitems are hidden
                padding: 0;
                overflow: hidden;

                &.children-expanded {
                  height: auto;
                }

                // Second level item
                .mat-mdc-list-item {
                  margin-top: variables.$nav-item-space-between;
                  min-width: variables.$nav-subitem-min-width;
                  height: variables.$nav-subitem-height !important;

                  mat-icon.mat-mdc-list-item-icon svg {
                    height: variables.$nav-subitem-icon-size;
                    width: variables.$nav-subitem-icon-size;
                  }
                }
              }

              // If parent has active then style subitems
              &.force-active,
              &.active {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                // Children container
                + mat-nav-list {
                  height: auto;
                  padding: 0 variables.$nav-subitem-padding-x variables.$nav-subitem-space-between;
                  background-color: variables.$nav-item-background-color-active;
                  border-bottom-left-radius: variables.$nav-item-radius;
                  border-bottom-right-radius: variables.$nav-item-radius;

                  &.expanded {
                    padding-left: variables.$nav-subitem-padding-x-expanded;
                    padding-right: variables.$nav-subitem-padding-x-expanded;
                  }

                  // Second level item
                  .mat-mdc-list-item {
                    &.active {
                      //background-color: variables.$nav-item-background-color-active;
                      &::before {
                        opacity: variables.$nav-subitem-overlay-opacity-active;
                      }
                    }

                    // Hover status
                    &:hover {
                      &::before {
                        opacity: variables.$nav-subitem-overlay-opacity-hover;
                      }
                    }

                    // Focus status
                    &:hover:focus {
                      &::before {
                        opacity: variables.$nav-subitem-overlay-opacity-focus;
                      }
                    }

                    &:focus {
                      &::before {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
            }

            & + .mat-mdc-list-item,
            & + mat-nav-list + .mat-mdc-list-item {
              margin-top: variables.$nav-item-space-between;
            }

            & ~ .spacer {
              height: variables.$nav-item-space-after;
            }
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TranslocoModule,
    MatListModule,
    NgTemplateOutlet,
    NgClass,
    MatDividerModule,
    RouterLinkActive,
    RouterLink,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class SidebarComponent {
  @Input() expanded = false
  @Input() isMobileSidebar = false
  @Output() itemClick: EventEmitter<SidebarItem | SidebarItemSlim> = new EventEmitter<SidebarItem | SidebarItemSlim>()
  @ContentChild('itemLayout') itemLayoutTemplateRef: TemplateRef<unknown>
  @Input() items: SidebarItem[]
  @Input() side: 'left' | 'right' = 'left'

  constructor(
    private store: Store,
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslocoService,
  ) {}

  hasPermissions(item: SidebarItem | SidebarItemSlim) {
    return item.perms?.length ? this.userService.hasAllCapabilities(item.perms) : true
  }

  onClickNavItem(item: SidebarItem | SidebarItemSlim) {
    this.itemClick.emit(item)
    if (this.isMobileSidebar) {
      this.store.dispatch(closeMobileMenu())
    }
  }

  openUpgradeDialog() {
    const data = {
      title: this.translateService.translate('alerts.genericUpgrade.Title'),
      content: this.translateService.translate('alerts.genericUpgrade.Body'),
    }
    this.dialogService.open(UpgradeDialogComponent, { data, width: '835px' })
    return
  }
}
